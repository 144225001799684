import config from '../config';
import {get, put, Delete, post} from '../http';

async function getByIdCompany(id, token) {
  const headers = {
    'Content-Type': 'application/json',
  };

  return await get(`${config.urlApi}/discount/IdCompany?id=${id}`, headers);
}

async function getById(id, token) {
  const headers = {
    'Content-Type': 'application/json',
    authorization: `Bearer ${token}`,
  };

  return await get(`${config.urlApi}/discount/Id?id=${id}`, headers);
}

async function create(discount, token) {
  const headers = {
    'Content-Type': 'application/json',
    authorization: `Bearer ${token}`,
  };
  const body = {
    data: {...discount},
  };
  return await post(`${config.urlApi}/discount`, headers, body);
}

async function update(discount, token) {
  const headers = {
    'Content-Type': 'application/json',
    authorization: `Bearer ${token}`,
  };
  const body = {
    data: {...discount},
  };
  return await put(`${config.urlApi}/discount`, headers, body);
}

async function DeleteDiscount(discount, token) {
  const headers = {
    'Content-Type': 'application/json',
    authorization: `Bearer ${token}`,
  };
  const body = {
    data: {...discount},
  };
  return await Delete(`${config.urlApi}/discount`, headers, body);
}
const functions = {
  getByIdCompany,
  create,
  DeleteDiscount,
  update,
  getById,
};

export default functions;
