/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

//MATERIAL-UI
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import classNames from "classnames";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Downshift from "downshift";

//IMPORTS
import deburr from "lodash/deburr";
import {
  createTheme,
  InputAdornment,
  MuiThemeProvider,
  TextField as MuiTextField,
  withStyles,
} from "@material-ui/core";

import cssStyles from "./styles.module.css";

const SuggestionInput = ({
  placeholder,
  suggestions,
  callback,
  styles,
  disabled,
  value,
  id,
  showSearchIcon = false,
  handleChange,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedItem, setSelectedItem] = useState("");

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) defaultHandleClick(selectedItem);
  };

  const defaultHandleClick = (value = undefined) => {
    const suggestion = getSuggestion(!value ? inputValue : value);
    callback(suggestion.value);
  };

  const getSuggestion = (label) =>
    suggestions
      .filter((item) => item.label)
      .find((item) => item.label.toLowerCase().includes(label.toLowerCase()));

  const handleInputChange = (event) => {
    handleChange(event.target.value);
  };

  const getSuggestions = (value) => {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
    return inputLength === 0
      ? []
      : suggestions
          .filter((item) => item.label)
          .filter((suggestion) => {
            const keep =
              count < 5 &&
              suggestion.label.slice(0, inputLength).toLowerCase() ===
                inputValue;

            if (keep) {
              count += 1;
            }
            return keep;
          });
  };

  return (
    <Downshift
      onChange={handleChange}
      inputValue={inputValue}
      selectedItem={selectedItem}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        highlightedIndex,
        inputValue,
        isOpen,
        selectedItem,
      }) => (
        <div className={styles.container}>
          {renderInput({
            styles: styles,
            id: id,
            showSearchIcon: showSearchIcon,
            InputProps: getInputProps({
              onChange: handleInputChange,
              onKeyDown: handleKeyDown,
              handleClick: defaultHandleClick,
              placeholder,
              disabled,
            }),
          })}
          <div {...getMenuProps()}>
            {isOpen ? (
              <Paper className={cssStyles.paper} square>
                {getSuggestions(inputValue).map((suggestion, index) =>
                  renderSuggestion({
                    suggestion,
                    index,
                    itemProps: getItemProps({
                      item: suggestion.label,
                    }),
                    highlightedIndex,
                    selectedItem,
                    setSelectedItem,
                    styles: styles,
                    handleClick: defaultHandleClick,
                    onKeyDown: handleKeyDown,
                  })
                )}
              </Paper>
            ) : null}
          </div>
        </div>
      )}
    </Downshift>
  );
};

function renderInput(inputProps) {
  const { InputProps, id, showSearchIcon, ref, styles, ...other } = inputProps;
  const { handleClick, disabled, placeholder } = InputProps;
  return (
    <MuiThemeProvider theme={theme}>
      <TextField
        variant="outlined"
        id={id}
        type="text"
        className={classNames(styles.SearchInput, "inputText")}
        onChange={InputProps.onChange}
        onKeyDown={InputProps.onKeyDown}
        value={InputProps.value}
        disabled={disabled}
        placeholder={placeholder}
        InputProps={{
          inputRef: ref,
          ...InputProps,
          startAdornment: showSearchIcon ? (
            <InputAdornment position="start">
              <IconButton
                aria-label="toggle password visibility"
                edge="start"
                className={styles.SearchInputIcon}
                onClick={() => handleClick()}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ) : (
            <div></div>
          ),
        }}
        {...other}
      />
    </MuiThemeProvider>
  );
}

function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem,
  styles,
  handleClick,
  setSelectedItem,
}) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || "").indexOf(suggestion.label) > -1;
  if (isHighlighted) setSelectedItem(suggestion.label);
  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
      className={classNames(
        cssStyles.option,
        isSelected ? cssStyles.selectedOption : ""
      )}
      onClick={() => handleClick(suggestion.label)}
    >
      <p className={classNames(styles.MenuItem)}>{suggestion.label}</p>
    </MenuItem>
  );
}

export default SuggestionInput;

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        // minHeight: 35,
      },
      notchedOutline: {
        border: "1px solid black !important",
        fontFamily: "Montserrat-Medium !important",
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "12px !important",
        borderRadius: 0,
      },
      // "$&focused": {
      //   border: "2px solid #122526",
      //   borderColor: "#122526",
      // },
      adornedEnd: {
        padding: "0 !important",
      },
      input: {
        padding: "5px",
      },
      adornedStart: {
        padding: "0 !important",
        paddingLeft: "5px !important",
      },
    },
    MuiTextField: {
      root: {
        // minHeight: 35,
        margin: "0px",
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: "Montserrat-Medium !important",
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "12px !important",
        color: "black !important",
        height: "28.5px !important",
        backgroundColor: "white !important",
      },
    },
    MuiInput: {
      root: {
        border: "none",
        color: "black !important",
        height: "28.5px !important",
        backgroundColor: "white !important",
      },
    },
  },
});

const TextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },
})(MuiTextField);
